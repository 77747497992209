import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebook,
  faGoogle,
  faYoutube,
  faVimeo,
  faLinkedinIn,
  faTwitter,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';

// import light icons
import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons';

// @todo: keep only needed ones from regular set , and later add ones u need as you go,
// Please don't import entire sets, keeps bundle small
import {
  faExternalLink,
  faCloudDownload,
  faFont,
  faSquare,
  faCheck,
  faClipboard,
  faSignOutAlt,
  faExclamationTriangle,
  faSearch,
  faInfoCircle,
  faLock,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircleNotch,
  faSpinner,
  faSpinnerThird,
  faUser,
  faUsers,
  faCode,
  faVideo,
  faShareAlt,
  faArrowCircleRight,
  faList,
  faEye,
  faQuestionCircle,
  faVolumeMute,
  faVolumeUp,
  faFastBackward,
  faFastForward,
  faPauseCircle,
  faPlayCircle,
  faDownload,
  faTrashAlt,
  faLifeRing,
  faSignIn,
  faMapMarkerAlt,
  faMinus,
  faPlus,
  faUndo,
  faEllipsisV,
  faTimes,
  faCog,
  faAngleLeft,
  faAngleRight,
  faSlidersV,
  faPenSquare,
  faExclamationCircle,
  faCloudUpload,
  faLink,
  faPhotoVideo,
  faCloudUploadAlt,
  faExclamation,
  faInfo,
  faBullseyePointer,
  faTh,
  faClone,
  faChartLine,
  faBars,
  faChartLineDown,
  faShoppingCart,
  faCoins,
  faMousePointer,
  faHeart,
  faAt,
  faImage,
  faComment,
  faSave,
  faGraduationCap,
  faPenAlt,
  faSyncAlt,
  faSignInAlt,
  faSignOut,
  faSlidersH,
  faHomeLgAlt,
  faProjectDiagram,
  faPollH,
  faFolder,
  faAnalytics,
  faUserShield,
  faUsersCog,
  faUsersClass,
  faClipboardList,
  faMailBulk,
  faUserGraduate,
  faSitemap,
  faGlobe,
  faCameraMovie,
  faBold,
  faEnvelopeOpen,
  faPaintBrush,
  faStopwatch,
  faCopy,
  faChevronLeft,
  faDesktop,
  faCalendar,
  faTachometer,
  faEnvelope,
  faBook,
  faRocketLaunch,
  faClock,
  faPlay,
  faPencilPaintbrush,
  faRedo,
  faRedoAlt,
  faImages,
  faTools,
  faRoad,
  faEdit,
  faGripLinesVertical,
  faChevronSquareLeft,
  faFilmAlt,
  faArrowToRight,
  faArrowToLeft,
  faCalendarAlt,
  faHouseUser,
  faSuitcase,
  faFunnelDollar,
  faUserCog,
  faClipboardUser,
  faUserChart,
  faBrowser,
  faCogs,
  faSync,
  faPalette,
  faArrowsAlt,
  faAddressCard,
  faEllipsisH,
  faCamera,
  faSmile,
  faShare,
  faThumbsUp,
  faFilm,
  faHome,
  faKey,
  faAngleDown,
  faBellOn,
  faPercent,
  faCircle,
  faText,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faSave as faSaveSolid,
  faStar as faStarSolid,
  faMapMarker as faMapMarkerSolid,
  faHeart as faHeartSolid,
  faPlay as faPlaySolid,
  faCircle as faCircleSolid,
  faPlayCircle as faPlayCircleSolid,
  faPauseCircle as faPauseCircleSolid,
  faCloudDownload as faCloudDownloadSolid,
  faCloudUpload as faCloudUploadSolid,
  faCaretDown
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faText,
  faCircle,
  faFont,
  faEdit,
  faAngleDown,
  faSquare,
  faTachometer,
  faEnvelope,
  faBook,
  faRocketLaunch,
  faClock,
  faPlay,
  faDesktop,
  faCalendar,
  faSignOut,
  faSlidersH,
  faHomeLgAlt,
  faProjectDiagram,
  faPollH,
  faFolder,
  faShareAlt,
  faArrowCircleRight,
  faAnalytics,
  faUserShield,
  faUsersCog,
  faUsersClass,
  faClipboardList,
  faMailBulk,
  faUserGraduate,
  faSitemap,
  faGlobe,
  faCameraMovie,
  faBold,
  faEnvelopeOpen,
  faPaintBrush,
  faStopwatch,
  faCopy,
  faChevronLeft,
  faSave,
  faGraduationCap,
  faPenAlt,
  faSyncAlt,
  faSignInAlt,
  faCloudDownload,
  faCheck,
  faClipboard,
  faSignOutAlt,
  faExclamationTriangle,
  faSearch,
  faInfoCircle,
  faLock,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircleNotch,
  faSpinner,
  faSpinnerThird,
  faUser,
  faUsers,
  faCode,
  faVideo,
  faList,
  faEye,
  faQuestionCircle,
  faVolumeMute,
  faVolumeUp,
  faFastBackward,
  faFastForward,
  faPauseCircle,
  faPlayCircle,
  faDownload,
  faTrashAlt,
  faLifeRing,
  faSignIn,
  faMapMarkerAlt,
  faMinus,
  faPlus,
  faUndo,
  faEllipsisV,
  faTimes,
  faCog,
  faAngleLeft,
  faAngleRight,
  faSlidersV,
  faPenSquare,
  faExclamationCircle,
  faCloudUpload,
  faLink,
  faPhotoVideo,
  faCloudUploadAlt,
  faExclamation,
  faInfo,
  faBullseyePointer,
  faTh,
  faClone,
  faChartLine,
  faBars,
  faChartLineDown,
  faShoppingCart,
  faCoins,
  faMousePointer,
  faHeart,
  faAt,
  faImage,
  faComment,
  faSaveSolid,
  faStarSolid,
  faHeartSolid,
  faCircleSolid,
  faPauseCircleSolid,
  faPlaySolid,
  faPlayCircleSolid,
  faMapMarkerSolid,
  faCloudDownloadSolid,
  faCloudUploadSolid,
  faFacebook,
  faGoogle,
  faYoutube,
  faVimeo,
  faLinkedinIn,
  faTwitter,
  faPinterestP,
  faPencilPaintbrush,
  faExternalLink,
  faRedo,
  faRedoAlt,
  faImages,
  faTools,
  faRoad,
  faSpinnerThird,
  faGripLinesVertical,
  faChevronSquareLeft,
  faFilmAlt,
  faArrowToRight,
  faArrowToLeft,
  faCalendarAlt,
  faHouseUser,
  faSuitcase,
  faFunnelDollar,
  faUserCog,
  faClipboardUser,
  faUserChart,
  faBrowser,
  faCogs,
  faSync,
  faPalette,
  faArrowsAlt,
  faAddressCard,
  faEllipsisH,
  faCamera,
  faSmile,
  faShare,
  faThumbsUp,
  faHeartLight,
  faFilm,
  faHome,
  faKey,
  faBellOn,
  faPercent,
  faCaretDown
);
