import React from 'react';
import PageBody from 'components/PageBody';
import { getAcl } from "./graphql/LocalState/acl";
import { useReactiveVar } from "@apollo/client";
import { setBreadcrumbs } from "./graphql/LocalState/breadcrumb";
import { setPageHeader } from "./graphql/LocalState/pageHeading";
import { useAuthUser } from "./graphql/User/hooks";


const UpgradePage = () => {

  const authUser = useAuthUser();

  setBreadcrumbs([
    { text: 'Upgrade' },
  ]);

  setPageHeader('Upgrade your account')

  return (
    <PageBody >
      <div className="grid" style={{ marginLeft: '30px' }}>
        <div className="w-100">
          <h3>Available Upgrades</h3>
        </div>
        <div className="w-100">
          <div>
            <h4 style={{ marginTop: '20px', marginBottom: '0px' }}>Interactr Pro</h4>
            {authUser.is_pro ? <p>Already Purchased</p> : <a style={{ color: 'blue' }} target="_blank" href="https://interactr.io/upgrade/pro" rel="noreferrer">Upgrade Here</a>}

            {/* <h4 style={{marginTop: '40px', marginBottom: '0px'}}>Interactr Pro Plus</h4>
            {authUser.usage_plan.name == "interactr_pro" ? <a target="_blank" href="https://app.paykickstart.com/checkout/rY7neP8l4O32dWaoD7Dj5EqG1pRoDw9V" rel="noreferrer">Upgrade Here</a> :  <p>Already Purchased</p>} */}

            <h4 style={{ marginTop: '40px', marginBottom: '0px' }}>Interactr Agency</h4>
            {authUser.is_agency ? <p>Already Purchased</p> : <a style={{ color: 'blue' }} target="_blank" href="https://interactr.io/upgrade/agency" rel="noreferrer">Upgrade Here</a>}

            <h4 style={{ marginTop: '40px', marginBottom: '15px' }}>Interactr Masterclass</h4>
            <a style={{ color: 'blue' }} target="_blank" href="https://interactr.io/upgrade/masterclass" rel="noreferrer">Upgrade Here</a>

            <h4 style={{ marginTop: '40px', marginBottom: '15px' }}>Purchase 1,000 Overage Minutes</h4>
            <a style={{ color: 'blue' }} target="_blank" href="https://app.paykickstart.com/checkout/pAYXBlDw6VGPdkggdKgkgqx0Z9Q1y5mR" rel="noreferrer">Purchase Here</a>

            <h4 style={{ marginTop: '40px', marginBottom: '15px' }}>Purchase 5,000 Overage Minutes</h4>
            <a style={{ color: 'blue' }} target="_blank" href="https://app.paykickstart.com/checkout/45nv3ME1weJgxz3JOdEjdL07plRBGqDo" rel="noreferrer">Purchase Here</a>

            <h4 style={{ marginTop: '40px', marginBottom: '15px' }}>Purchase 20,000 Overage Minutes</h4>
            <a style={{ color: 'blue' }} target="_blank" href="https://app.paykickstart.com/checkout/qVBwA5YD1K90djOX6DazGEovQr4OJnyp" rel="noreferrer">Purchase Here</a>
          </div>
        </div>
      </div>
    </PageBody>
  );
};
export default UpgradePage;
